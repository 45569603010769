.centered-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 26.688rem;
}
.login-title {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
}


