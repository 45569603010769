@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,
body {
  font-family: "Poppins", sans-serif;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background-color: #f5f6f8;
  scroll-behavior: smooth;
  overflow: hidden;
}
.page-scroll-x {
  overflow-x: auto;
}
.page-scroll-y {
  overflow-y: auto;
}
.content-center {
  display: grid;
  place-items: center;
}
.row-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-centers {
  display: flex;
  align-items: center;
}
.ml-5 {
  margin-left: 5px;
}
/* Input Field */
input {
  border: 1px solid #e4edf0;
  padding: 0.75rem 1.25rem;
  width: 100%;
  outline: none;
  border-radius: 0.375rem;
}

input::placeholder {
  color: #a0aec0;
  font-size: 0.875rem;
}
.input-label {
  display: block;
  font-size: 0.938rem;
  margin-bottom: 0.625rem;
}

.custom-input {
  border: none !important;
  outline: none !important;
  padding: 0.75rem 1.3rem !important;
  min-width: 50px;
  max-height: 10px !important;
}
/* Button Field */
.btn {
  border-radius: 0.25rem;
  padding: 0.75rem 1.25rem;
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
}
.btn-primary {
  color: #ffffff;
  background-color: #f14c52;
}
.btn-primary:hover {
  /* opacity: 0.9; */
  /* box-shadow: 0px 5px 10px 0px rgba(255, 0, 140, 0.329); */
  /* transform: translate(0px, -2px); */
}
.btn-secondary {
  color: #f14c52;
  border: 1px solid #f14c52;
}
/* Margin */
.mb-20 {
  margin-bottom: 1.25rem;
}
::-webkit-scrollbar {
  display: none !important;
}
/* sidebar */

.sidebar {
  width: 4.3vw;
  background-color: #333333;
  height: 100vh;
  /* overflow: auto; */
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  transition: all 0.3s;
}
.sidebar-footer {
  margin-top: 20px;
}
.sidebar-footer img:hover {
  cursor: pointer;
}
.sidebar-popup {
  position: absolute;
  top: -67px;
  left: 45px;
  width: 200px;
  border-radius: 6px;
  padding: 16px 12px;
  border: 1px solid #f6f6f6;
  background-color: #fff;
  box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all 0.3s;
}
.sidebar-popup p img {
  width: 16px;
  filter: brightness(0) saturate(100%) invert(0%) sepia(4%) saturate(0%)
    hue-rotate(278deg) brightness(94%) contrast(104%);
  transition: all 0.3s;
}
.sidebar-popup p {
  margin: 0;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s;
}
.sidebar-popup p:hover {
  color: #f14c52;
}
.sidebar-popup p:hover img {
  filter: brightness(0) saturate(100%) invert(34%) sepia(29%) saturate(6905%)
    hue-rotate(336deg) brightness(110%) contrast(88%);
}
.profile-menu {
  position: relative;
  transition: all 0.3s;
}
.pass-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.content {
  flex: 1;
  height: 100vh;
  padding: 1.875rem 2rem;
  text-align: justify;
  overflow: auto !important;
}
.sidebar-module {
  border-radius: 10px;
  padding: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebar-active,
.sidebar-module:hover {
  background-color: #ffffff1a;
}
.sidebar-module img {
  width: 1.25rem;
  height: 1.25rem;
}

/* Container */
.container {
  margin: 1.875rem 1.25rem;
}

.module-name {
  color: #333333;
  font-weight: 600;
  font-size: 1.375rem;
}

.cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 32.3%;
  background-color: #ffffff;
  border-radius: 0.375rem;
  padding: 1.25rem 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
}
.dash-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.375rem;
  padding: 1.25rem 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
}
.white-space {
  /* display: flex;
  justify-content: space-between; */
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.375rem;
  padding: 1.25rem 1.25rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
}
.card-name {
  color: #a0aec0;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
}
.card-value {
  color: #333333;
  font-size: 1.063rem;
  font-weight: 500;
}
.card-img {
  background-color: rgba(241, 76, 82, 0.06);
  padding: 0.563rem;
  border-radius: 0.625rem;
}
.card-title {
  color: #f14c52;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
}
.module-secondary-name {
  color: #333333;
  font-weight: 600;
  font-size: 1.125rem;
}
.module-chart-title {
  color: #333333;
  font-weight: 600;
  font-size: 0.875rem;
}
.chart {
  width: 50%;
  /* height: 28.125rem; */
  background-color: #ffff;
  border-radius: 0.375rem;
  padding: 1.25rem 0.938rem;
}
.paidplan {
  border-left: 1px solid #e4edf0;
  height: 45px;
  padding-right: 30px;
  margin-top: -10px;
}
.btn-filter {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 0.75rem;
  border: 1px solid #757575;
  padding: 0.75rem 1.125rem;
  border-radius: 0.375rem;
}
.affiliate-filter img,
.btn-filter img {
  margin-right: 0.5rem;
}
.affiliate-filter {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 0.75rem;
  border: 1px solid #e4edf0;
  padding: 0.75rem 1.125rem;
  border-radius: 0.375rem;
}

table {
  width: 100%;
  /* table-layout: fixed; */
  background-color: #ffffff;
  border-radius: 6px;
}
table thead tr {
  padding: 24px 0px;
  border-bottom: 1px solid #cfdde1;
}

table thead tr th {
  color: #333333;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 16px 20px;
  text-align: left;
  text-transform: uppercase;
}
table tbody tr {
  border-bottom: 1px solid #e4edf0;
}
table tbody tr:last-child {
  border-bottom: none !important;
}
table tbody tr td {
  color: #757575;
  font-size: 0.813rem;
  font-weight: 500;
  padding: 16px 20px;
}
.pagination {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 0.875rem;
}
.changeBtn {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lessthan {
  display: inline-block; /* Ensures the transform works properly */
  font-size: 50px;
  transform: rotate(180deg);
  cursor: pointer;
}
.activePage {
  background-color: #f14c52;
  color: #ffffff;
  padding: 0.125rem 0.625rem;
  border-radius: 50%;
}
.statusdropdown {
  position: relative;
  width: 100%;
  display: flex;
  background-color: #0f91d20f;
  border-radius: 4px;
  padding: 9px 12px;
  color: #0f91d2;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
}
.statusdropdown-list {
  position: absolute;
  background-color: #fff;
  color: #757575;
  width: fit-content;
  font-size: 0.875rem;
  font-weight: 400;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  width: 100%;
  min-width: 120px;
  margin-top: 8px;
  padding: 10px;
  z-index: 1;
}

.statusdropdown-list p {
  padding: 5px;
  color: #757575;
  transition: all 0.3s;
}

.statusdropdown-list p:hover {
  cursor: pointer;
  border-radius: 3px;
  color: #f14c52;
  background-color: #f1f1f1;
}
.dropdown {
  position: relative;
  width: 100%;
  border: 1px solid #e4edf0;
  display: flex;
  background-color: #ffffff;
  border-radius: 0.375rem;
  padding: 0.813rem 1rem;
  color: #757575;
  font-weight: 500;
  font-size: 0.75rem;
  cursor: pointer;
}
.custom-input-affiliate {
  border: 1px solid #e4edf0 !important;
  outline: none !important;
  padding: 0.5rem 1.6rem !important;
}
.multi-dropdown-list {
  position: absolute;
  background-color: #fff;
  width: fit-content;
  font-size: 13px;
  font-weight: 400;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  width: 100%;
  min-width: 120px;
  margin-top: 8px;
  padding: 10px;
}
.dropdown-list {
  position: absolute;
  background-color: #fff;
  width: fit-content;
  font-size: 0.875rem;
  font-weight: 400;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  width: 100%;
  min-width: 120px;
  margin-top: 8px;
  padding: 10px;
}
.dropdown-list p,
.multi-dropdown-list div {
  padding: 5px;
  transition: all 0.3s;
}

.dropdown-list p:hover,
.multi-dropdown-list div:hover {
  cursor: pointer;
  border-radius: 3px;
  color: #f14c52;
  background-color: #f1f1f1;
}

.ant-message {
  z-index: 100000050 !important;
}
.truncate-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.max-w-200 {
  max-width: 200px;
}

.max-w-100 {
  max-width: 100px;
}
.align-center {
  align-items: center;
}
.f12 {
  font-size: 12px;
}
.f13 {
  font-size: 13px;
}
.f14 {
  font-size: 14px;
}
.f15 {
  font-size: 15px;
}
.f16 {
  font-size: 16px;
}
.f17 {
  font-size: 17px;
}
.f18 {
  font-size: 18px;
}
.fw400 {
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.mr8 {
  margin-right: 8px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}
.clB5B5B5 {
  color: #b5b5b5;
}
.flexCol {
  display: flex;
  flex-direction: column;
}
.w14 {
  width: 14%;
}
.mb6 {
  margin-bottom: 6px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mt20 {
  margin-top: 20px;
}
.inputBorder {
  border: 1px solid #e4edf0;
  border-radius: 4px;
}
.radius-6 {
  border-radius: 6px;
}
.activate {
  padding: 7px 8px;
  border-radius: 4px;
}
.w30 {
  width: 30% !important;
}
.ant-picker {
  border-color: #e4edf0; /* Custom border color */
}

.ant-picker:hover {
  border-color: #e4edf0 !important; /* Keep border color consistent on hover */
}
.flex_sb {
  display: flex;
  justify-content: space-between;
}
.sales_cards {
  min-width: 19.375rem;
  height: 7.5rem;
  background: #fffeff;
  box-shadow: 0rem 0.063rem 0.188rem rgba(0, 0, 0, 0.08);
  border-radius: 0.625rem;
  align-items: center;
  padding: 1.25rem;
  border: 0.125rem solid #f3f1f3;
}
.shimmer1 {
  color: #f3f1f3;
  background: #fffeff;
  mask: linear-gradient(120deg, #000 30%, #0005, #000 70%) right/300% 100%;
  -webkit-mask: linear-gradient(120deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer1 1.5s infinite;
}

@keyframes shimmer1 {
  100% {
    -webkit-mask-position: left;
  }
}
.line {
  width: 100%;
  height: 1.063rem;
  background: #f0eef0;
  margin: 1.25rem 0;
  border-radius: 0.313rem;
}
.shimround {
  width: 14%;
  height: 3rem;
  border-radius: 3.125rem;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.spincenter {
  align-items: center;
  height: 70vh;
  justify-content: center;
}
.h50vh {
  height: 50vh !important;
}
.spinred {
  color: #f14c52;
}
.spinred_white {
  color: #ffffff;
}
.f15 {
  font-size: 15px;
}
.zoom {
  transition: transform 0.3s ease-in-out;
}
.zoom:hover {
  transform: scale(1.04);
  transition: transform 0.5s;
}

.discount-field {
  border: 1px solid #e4edf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  width: 100%;
  border-radius: 0.375rem;
}
.dicount-per {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
  color: #333333;
  font-size: 14px;
  border-left: 2px solid #e4edf0;
  padding-left: 1rem;
}
.ant-picker-outlined:focus-within {
  box-shadow: none !important;
}
.ellipsis {
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide any overflow text */
  text-overflow: ellipsis; /* Add the ellipsis (…) to indicate overflow */
}
.status-icon {
  filter: brightness(0) saturate(100%) invert(50%) sepia(47%) saturate(7435%)
    hue-rotate(176deg) brightness(99%) contrast(88%);
}
input[type="checkbox"]:checked {
  background-color: lightblue;
}
