.discount-pagination{
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}
.discount-pagination div{
     display: flex;
     gap: 30px;
}
.previous_arrow{
    rotate: 180deg;
}
.arrow{
    padding: 7px 8px;
}
.arrows:hover{
    background-color: rgb(221 221 221);
    border-radius: 3px;
    cursor: pointer;
}