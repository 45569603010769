.tabname {
  display: flex;
  gap: 30px;
  font-size: 1rem;
  border-bottom: 1px solid #d5ddec;
  transition: all 0.3s;
}
.select-tab {
  color: #f14c52;
  font-weight: 500;
  padding-bottom: 0.875rem;
  border-bottom: 2px solid #f14c52;
  cursor: pointer;
}
.unselect-tab {
  color: #757575;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s;
}
.affiliate-head {
  padding: 24px 20px 32px 20px;
}

.pointer {
  cursor: pointer;
}

.breadcrumb {
  list-style: none;
  display: flex;
  gap: 10px;
}

.breadcrumb-item {
  font-size: 14px;
}

.breadcrumb-item a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb-item span {
  color: #6c757d;
}
