.popup-container {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  display: grid;
  overflow: scroll;
  transition: all 0.3s;

  /* overflow: hidden; */
}
.modal-pop {
  place-self: center;
  width: max(23vw, 436px);
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px 30px;
  border-radius: 8px;
  font-size: 14px;
  animation: fadeIn 0.5s;
  transition: all 0.3s;

}
.login-popup-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #252525;
  font-size: 20px;
}
