.text-style {
  margin-bottom: 20px;
}
.labelStyle {
  display: block;
  color: #757575 !important;
  font-size: 12px !important;
  margin-bottom: 0.625rem;
  font-weight: 400;
}
.logout {
  text-align: center;
}
.logout p:first-child {
  color: #252525;
  /* font-weight: 400; */
  font-size: 16px;
  margin-bottom: 24px;
}
.logout p:nth-child(2) {
  color: #252525;
  /* font-weight: 400; */
  font-size: 16px;
  margin-bottom: 24px;
}
.cl757575 {
  color: #757575 !important;
}
.cl333333 {
  color: #333333 !important;
}
.tooltiptext {
  position: absolute;
  visibility: hidden;
  left: 70px;
  min-width: 130px;
  border-radius: 6px;
  padding: 8px 10px;
  color: #f6f6f6;
  background-color: #333333;
  box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  font-size: 14px;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333333 transparent transparent;
}

.sidebar-nav:hover .tooltiptext {
  visibility: visible;
}
